:root {
  --color-background: #010101;
  --color-header-font-light: #3d3935;
  --color-white: #e6e6e6;
  /* --color-white: #fefefe; */
  --color-red: #ed2224;
  --color-yellow: #f9f82c;
  --color-grey: #777764;
  --color-shadow: #777764be;
  --color-footer-font: #b7b7b7;
  --color-overlay: rgba(0, 0, 0, 0.5);
  --color-concrete: rgb(169, 169, 169);
  --color-steel: rgb(52, 104, 192);
  --color-wood: rgb(255, 187, 100);
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
}
body {
  background-color: var(--color-background);
  color: var(--color-white);
  font-family: "Roboto", sans-serif;
}
/*----------------HEADER----------------*/

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

.navbar {
  background-color: var(--color-yellow);
  display: flex;
  align-items: center;
  height: 50px;
}

.main-menu {
  margin-left: auto;
}

/* Applies to the menu items. */
.navbar .main-menu ul {
  display: flex;
}

.navbar ul li a {
  color: var(--color-header-font-light);
  font-weight: 400;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  padding: 10px 10px;
  transition: 0.3s;
}

.links-to-main {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}

/* adds space around the final item, i.e., the button. */
.navbar ul li:last-child a {
  margin-left: 10px;
}

/* adds space betweent the icon and the icon text. */
.navbar ul li i {
  margin-right: 10px;
}

/* burger menu */

.menu-icon {
  display: none;
  cursor: pointer;
  z-index: 999;
}

.menu-icon div {
  width: 30px;
  height: 3px;
  background-color: var(--color-header-font-light);
  margin: 6px 0;
  transition: 0.4s;
}

.menu-icon:hover div {
  background-color: var(--color-grey);
}

.menu-icon.open div:nth-child(1) {
  transform: rotate(-45deg) translate(-6.5px, 6px);
}

.menu-icon.open div:nth-child(2) {
  opacity: 0;
}

.menu-icon.open div:nth-child(3) {
  transform: rotate(45deg) translate(-6.5px, -6px);
}

/* --------------------------------ALL - OPTIONS----------------------------*/
.app-name {
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  border-left: 3px solid var(--color-yellow);
  padding-left: 25px;
}
.container {
  padding: 0px 20px;
}

.main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
}

.options-page-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.options-reset {
  margin-top: 10px;
}
.btn-width {
  display: flex;
  justify-content: space-between;
  width: 315px;
}

.container-space-between {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.container-description {
  display: flex;
  align-items: baseline;
}
.btn-all {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn-options {
  display: flex;
  flex: 1;
}

.btn-comparison {
  margin-left: 21px;
}

.btn.operations {
  width: 120px;
}

.btn,
.btn-reset {
  font-size: 18px;
  text-decoration: none;
  padding: 5px;
  cursor: pointer;
  margin-top: 10px;
  border: 1px solid var(--color-grey);
  background-color: var(--color-background);
  color: var(--color-white);
  margin-right: 20px;
}

.btn-reset {
  margin-right: 0px;
}

.btn:last-child {
  margin-right: 0px;
}

.btn:hover {
  border: 1px solid var(--color-yellow);
  color: var(--color-yellow);
}

.btn-reset:hover {
  border: 1px solid var(--color-red);
  color: var(--color-red);
}

/* --------------------------------OPTIONS----------------------------*/

.container-scheme-description {
  margin-top: 35px;
}

.container-area {
  margin-top: 15px;
}

.input-area-fixed {
  width: 175px;
  text-align: left;
}

.label-area-fixed {
  width: 145px;
}

.btn.active {
  border: 1px solid var(--color-yellow);
  color: var(--color-background);
  background-color: var(--color-yellow);
}

.btn.disabled {
  color: var(--color-grey);
  position: relative; /* Needed for tooltip positioning */
}

.tooltip {
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  color: var(--color-white);
  white-space: nowrap;
  z-index: 100;
}

.btn.disabled:hover .tooltip {
  display: block;
}

.btn.operations:disabled {
  color: var(--color-grey);
  cursor: not-allowed;
  border: 1px solid var(--color-grey);
}

.select-material {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin: 40px 0px;
}

.material-button {
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 0px);
  height: 200px;
  border: none;
  background: none;
  box-sizing: border-box;
  padding-top: calc((100% / 16) - 20px);
  padding-bottom: calc((100% / 16) - 20px);
}

.material-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  opacity: 60%;
}

.overlay-active {
  background-color: var(--color-overlay);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  display: block;
  border: 1px solid var(--color-yellow);
}

.active-text {
  color: var(--color-yellow);
  z-index: 3; /* ensures it's above the overlay */
}

.material-image:hover {
  background: var(--color-background);
  transition: 0.3s ease-in-out;
}

.icon-text {
  /* font-family: "Shadows Into Light", cursive; */
  font-family: "Roboto";
  font-weight: 400;
  font-size: 24px;
  color: var(--color-white);
  cursor: pointer;
  text-align: center;
  z-index: 3;
}

.icon-text.active-text {
  color: var(--color-yellow);
  z-index: 99;
}

.value-row {
  display: flex;
  align-items: center;
}

.value-row-placeholder {
  width: 402.73px;
}

.menu-text-emph {
  font-size: 16px;
  color: var(--color-white);
  font-weight: 400;
  text-decoration: underline var(--color-white);
  text-underline-offset: 4px;
}

.text-semibold {
  font-weight: 400;
}
.lh-20 {
  line-height: 20px;
}

.menu-text-large {
  font-size: 16px;
  color: var(--color-white);
  font-weight: 300;
  margin-right: 10px;
}

.menu-text-medium {
  font-size: 16px;
  font-weight: 300;
  /* line-height: 25px;  */
}

.menu-text-small {
  font-size: 12px;
  font-weight: 300;
  /* line-height: 18px; */
}

.text-yellow {
  color: var(--color-yellow);
}

.concrete {
  color: var(--color-concrete);
}

.steel {
  color: var(--color-steel);
}

.wood {
  color: var(--color-wood);
}

.question-mark-text {
  position: absolute;
  margin-top: 3px;
}

.header-link {
  color: var(--color-yellow);
  cursor: pointer;
  text-decoration: none;
}

.header-link:hover {
  text-decoration: underline;
}

.error-message {
  margin-top: 10px;
  font-size: 13px;
  color: var(--color-red);
}

.error-message-input {
  position: absolute;
  margin-top: 50px;
  margin-left: 80px;
  font-size: 11px;
  color: var(--color-red);
}
.conv {
  margin-left: 5px;
  width: 50px;
}

.description-text {
  font-size: 18px;
  color: var(--color-yellow);
  font-weight: 300;
}

.value-name {
  width: 120px;
}

.value-value {
  width: 100px;
  margin-left: 20px;
}

input {
  width: 80px;
  font-size: 16px;
  font-family: "Roboto";
  padding: 5px;
  background-color: var(--color-background);
  color: var(--color-white);
  text-align: right;
  border: none;
  border-bottom: 2px solid var(--color-yellow);
}

/* For Chrome, Safari, Edge, and Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.input-hide {
  border-bottom: none;
}

.input-description {
  width: 180px;
}

.value-row-GWP {
  width: 70px;
  margin-left: 10px;
}

.value-row-description {
  display: flex;
  width: 800px;
  justify-content: flex-end;
  padding: 0px;
  margin-bottom: -25px;
}
.description-label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60px;
}

/* MODALS */

.modal {
  display: inline-block;
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  width: 250px;
  background-color: var(--color-background);
  padding: 20px;
  border: 1px solid var(--color-yellow);
  box-shadow: 0 4px 8px var(--color-shadow);
  font-size: 14px;
  color: var(--color-yellow);
  font-weight: 300;
}

.blur-effect {
  filter: blur(5px);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-overlay);
  z-index: 98;
}

.modal-content {
  position: relative;
  margin-left: 50px;
}

.modal-par {
  display: flex;
  align-items: flex-start;
}

.plus-text {
  margin-top: 16px;
}

.modal-description {
  display: inline-block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  width: 950px;
  max-height: 80vh;
  overflow-y: auto;
  background-color: var(--color-background);
  padding: 20px 30px;
  border: 1px solid var(--color-yellow);
  box-shadow: 4px 4px 8px var(--color-shadow);
  color: var(--color-white);
  font-weight: 300;
}

.app-about {
  display: flex;
  align-items: center;
}

.about {
  transition: transform 0.3s ease-in-out;
  align-self: center;
  margin-left: 20px;
}

.about:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.about.hover-active {
  transform: scale(1.2) rotate(360deg);
  transition: transform 1s ease-in-out;
}

.about-description-short {
  width: 515px;
  text-align: justify;
}

.modal-description-content {
  position: relative;
  margin-left: 10px;
}

.close {
  margin-left: 10px;
  color: var(--color-yellow);
}
.close:hover {
  color: var(--color-red);
}

.btn-small {
  position: absolute;
  bottom: -30px;
  right: 1px;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid var(--color-grey);
  background-color: var(--color-background);
  color: var(--color-white);
}

.btn-small:hover {
  border: 1px solid var(--color-yellow);
  color: var(--color-yellow);
}

.close {
  position: absolute;
  top: -30px;
  right: 1px;
  font-size: 20px;
  cursor: pointer;
}

/* --------------------------------OPTION RESULTS----------------------------*/
.option-materials-inputs {
  display: flex;
}
.optionResults {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.total-values {
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: flex-start;
}
.total-value-name {
  width: 80px;
}

.chart-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.chart-title-stacked {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.chart-scheme {
  display: flex;
  justify-content: center;
  align-items: center;
}

.data-col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 200px;
  margin-bottom: 30px;
  margin-left: -40px;
}

/* --------------------SUMMARY-------------------- */

.container-summary {
  display: flex;
  flex-direction: column;
}

.btn.print {
  align-self: flex-start;
  margin: 20px 0px 0px 20px;
}

.print {
  align-self: flex-end;
  margin-bottom: 20px;
}

.container-charts-all {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.chart-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 575px;
}

.summary {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.chart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
}

.chart-description {
  font-size: 20px;
  margin-top: -10px;
}

.chart-area {
  margin-top: 15px;
  margin-left: 10px;
}

.left-offset {
  margin-left: 70px;
}

/* ------------------ TABLES -------------------*/
.tables {
  display: flex;
  align-items: space-around;
  justify-content: space-around;
}

table {
  border-collapse: collapse;
  width: 100%;
  margin: 10px;
}

table thead th {
  text-align: center;
}

table th,
table td {
  border: 1px solid var(--color-grey);
  text-align: center;
  padding: 3px;
  width: 100px;
}

table th:first-child,
table td:first-child {
  width: 30px;
}

.th-description {
  width: 120px;
  padding: 0px 5px;
}

.img-table {
  width: 35px;
}

.danger {
  color: var(--color-red);
}

.info {
  color: #4f7942;
  font-weight: 400;
}
/* ---------------------LOGO----------------------- */

.logo {
  margin-top: 10px;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 200;
}

.container.print.logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-image {
  width: 25px;
  height: auto;
  align-self: flex-end;
}

/* -----------------PRINT PDF STYLE--------------- */
.print-only {
  display: none;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
}

@media print {
  @page {
    size: 17in 11in;
  }

  body,
  .container-summary {
    width: 100%;
    margin: 0 auto;
    transform: scale(1);
    transform-origin: center center;
  }

  .container-space-between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .summary {
    padding: 10px 20px;
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
  }

  .print-only {
    display: flex;
  }

  .footer {
    display: block;
    position: fixed;
    bottom: 20px;
    left: 0;
    right: 0;
    text-align: center;
  }

  .container-charts-all {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}

/* -----------------FOOTER--------------- */
.site-footer {
  width: 100%;
  display: flex;
  align-items: baseline;
  padding: 30px 20px;
  box-sizing: border-box;
  margin-bottom: 0;
}

.site-footer * {
  margin: 0;
  padding: 0;
  text-align: center;
}

.social-logo {
  margin: 0px 7px;
  background-color: var(--color-footer-font);
}

.social-logo:hover {
  background-color: var(--color-red);
}

.instagram-logo {
  width: 20px;
}

.twitter-logo {
  width: 25px;
}

.linkedin-logo {
  width: 23px;
  margin: 0px 3px;
}

.container-footer {
  display: flex;
  align-items: baseline;
  justify-content: space-around;
  width: 100%;
}

.copyright,
.newsletter,
.terms a {
  font-size: 11px;
  font-weight: 200;
  line-height: 21px;
  font-family: "Roboto", sans-serif;
  color: var(--color-footer-font);
  text-decoration: none;
}
.terms a:first-child {
  margin-right: 10px;
}

.terms a:hover {
  text-decoration: underline;
}

.newsletter-link {
  margin-left: 15px;
  color: var(--color-footer-font);
  font-size: 24px;
  font-weight: 400;
  cursor: pointer;
  text-decoration: underline var(--color-red);
  text-underline-offset: 4px;
}

.newsletter-link:hover {
  color: var(--color-red);
}

@media (max-width: 1788px) {



  .menu-text-large {
    font-size: 14px;
    margin-right: 5px;
  }

  .menu-text-medium {
    font-size: 12px;
  }

  input {
    width: 60px;
    font-size: 14px;
  }

  .label-area-fixed {
    width: 130px;
  }

  .input-area-fixed {
    width: 169px;
  }

  .value-name {
    width: 100px;
  }

  .value-row-placeholder {
    width: 335.34px;
  }

  .modal-description {
    width: 750px;
  }

  .plus-text {
    margin-top: 10.5px;
    width: 20px;
  }

  .chart-container {
    height: 440px;
  }
}

@media (max-width: 1150px) {
  .btn.print {
    display: none;
  }

  .chart-description {
    margin-top: 20px;
  }

  .container-space-between {
    display: flex;
    flex-direction: column;
  }

  .btn,
  .btn-reset {
    font-size: 14px;
  }
  .btn-comparison {
    margin-left: 45px;
  }

  .btn.print {
    margin-bottom: 20px;
  }

  .about-description-short {
    width: 502px;
  }

  .container-charts-all {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .summary {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
  }
  .chart {
    margin: 20px 0px;
  }
}

/* burger menu at this point */
@media (max-width: 959px) {
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .links-to-main {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    background-color: var(--color-white);
    transform: translateX(100%);
    transition: transform 0.3s ease-out;
    z-index: 100;
  }
  
  ul {
    padding-inline-start: 0px;
}

.navbar ul li:last-child a {
    margin-left: 0px;
}


  .links-to-main.open {
    transform: translateX(0);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 40px;
  }

  .main-menu ul {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .main-menu ul li {
    margin-bottom: 30px;
  }

  .main-menu {
    margin-left: 0px;
  }

  .menu-icon {
    display: block;
  }
}

@media (max-width: 768px) {
  .site-footer {
    flex-direction: column;
  }

  .container-footer {
    flex-direction: column;
    align-items: center;
  }

  .social-logo {
    margin: 3px;
  }

  .modal-description {
    width: 500px;
  }
}

@media (max-width: 720px) {
  .btn.print {
    margin-left: 20px;
    width: 100px;
  }

  .about-description-short {
    width: 500px;
  }

  .app-about {
    align-self: flex-start;
  }
  .select-material {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .option-material {
    margin-top: 20px;
  }
}

@media (max-width: 600px) {
  .container-summary {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .btn-width {
    width: 285px;
  }

  .btn,
  .btn-reset {
    margin-right: 20px;
  }

  .btn-comparison {
    margin-left: 32px;
  }

  .btns-save-calc {
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }
  .modal-description {
    width: 470px;
  }
}

@media (max-width: 540px) {
  .btn-width {
    width: 270px;
  }

  .btn-comparison {
    width: 113px;
  }

  .btn-options {
    flex: 0;
  }

  .btn,
  .btn-reset {
    font-size: 12px;
    padding: 3px;
  }

  .btn-reset {
    width: 150px;
  }

  .chart {
    width: 300px;
  }
  .modal-description {
    width: 350px;
    max-height: 90vh;
    padding: 15px 25px;
  }

  .about-description-short {
    width: 450px;
  }
}

@media (max-width: 500px) {
  .app-name {
    font-size: 20px;
  }

  /* .select-material {
    margin: 10px 0px;
    width: 300px;
  } */

  .material-button {
    height: 180px;
  }

  .about-description-short {
    width: 380px;
  }

  .btn-width .btn,
  .btn-comparison {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .btn-width {
    display: flex;
    flex-direction: column;
    width: 230px;
  }
  .btn-comparison {
    width: 95px;
  }

  .chart-description {
    font-size: 14px;
  }

  .btn,
  .btn-reset {
    width: 60%;
    height: 100%;
    font-size: 10px;
  }

  .btn-reset {
    width: 44%;
  }

  .th-description {
    width: 80px;
    padding: 0px 3px;
  }

  .menu-text-medium {
    font-size: 10px;
  }
  .img-table {
    width: 15px;
  }

  .modal-description {
    width: 300px;
    max-height: 70%;
  }

  /* .main-container {
    width: 80%;
  } */
  .about-description-short {
    width: 350px;
  }
}
